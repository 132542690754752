
import { defineComponent, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useDeals, useDeleteDeal } from '@/composables/api';
import { DealIdOptions } from '@/services/api';
import { Delete as DeleteIcon } from '@element-plus/icons';
import permissionUnits from '@/components/permission-units/index.vue';

import SearchBox from '@/components/keyword-searching/Index.vue';

export default defineComponent({
  components: {
    SearchBox,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref('');
    const { data, isLoading, isFetching, refetch } = useDeals({ page, keyword });
    const { isLoading: isDeletedLoading, mutate } = useDeleteDeal();
    const statusIndex = ref(undefined);
    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const deleteDeal = ({ dealId }: DealIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { dealId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      isDeletedLoading,
      deleteDeal,
      statusIndex,
      searchKeyword
    };
  }
});
